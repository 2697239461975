import 'minireset.css'
import 'styles/globals.css'
import 'styles/react_toggle.scss'

import 'services/promise'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import Head from 'next/head'
import React, { useEffect } from 'react'
import Updater from 'services/updater'
import client from 'services/data/client'
import honey from 'services/honey'
import { QueryClientProvider } from 'react-query'

// TODO(ibash) ensure bugsnag is correct here
// ref: https://github.com/bugsnag/bugsnag-js/issues/811#issuecomment-715329581
Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  appVersion: process.env.buildId,
  enabledReleaseStages: ['production'],
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_PUBLIC_APP_ENV
})

if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
  // bugsnag swallows errors...
  const originalNotify = Bugsnag.notify
  Bugsnag.notify = function (error) {
    console.error(error)
    return originalNotify.apply(this, arguments)
  }
}

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// TODO(ibash) add bugsnag here
// It appears bugsnag doesn't support next.js easily, so this needs a proper
// investigation + implementation.
//
// ref: https://github.com/bugsnag/bugsnag-js/issues/811#issuecomment-715329581
export default function App({ Component, pageProps }) {
  useEffect(() => {
    if (!process.browser) {
      return
    }

    console.log('starting up', `buildId: ${process.env.buildId}`)

    const isElectron = window && window.process && window.process.type
    if (!isElectron) {
      window.location = process.env.NEXT_PUBLIC_BASE_URL
      return
    }

    const updater = new Updater()
    updater.start()

    honey.track('app.load')
  }, [])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Summon</title>
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
      <ErrorBoundary>
        <QueryClientProvider client={client.queryClient}>
          <Component {...pageProps} />
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  )
}
