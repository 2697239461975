// extensions to promises
import Timeout from 'await-timeout'

Promise.debounce = function (callback) {
  if (callback.__debounce && callback.__debounce.fn) {
    return callback.__debounce.fn
  }

  callback.__debounce = {}

  callback.__debounce.fn = async function () {
    if (callback.__debounce.inProgress) {
      return callback.__debounce.inProgress
    }

    try {
      callback.__debounce.inProgress = callback.apply(this, arguments)
      const result = await callback.__debounce.inProgress
      return result
    } finally {
      callback.__debounce.inProgress = null
    }
  }

  return callback.__debounce.fn
}

Promise.delay = function (timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

Promise.resolvable = function () {
  let resolve, reject
  // eslint-disable-next-line promise/param-names
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })
  promise.resolve = resolve
  promise.reject = reject
  return promise
}

Promise.TimeoutError = class TimeoutError extends Error {
  constructor(message) {
    super(message || 'timeout error')
    this.name = 'TimeoutError'
  }
}

Promise.timeout = function (promise, delay, reason) {
  let error
  if (reason instanceof Error) {
    error = reason
  } else if (typeof reason === 'string') {
    error = new Promise.TimeoutError(reason)
  } else {
    error = new Promise.TimeoutError()
  }
  return Timeout.wrap(promise, delay, error)
}
