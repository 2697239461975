import Timeout from 'await-timeout'

export default class Updater {
  async start() {
    let isReloading = false

    navigator.serviceWorker.addEventListener('controllerchange', function () {
      if (isReloading) {
        return
      }
      window.location.reload()
      isReloading = true
    })

    while (true) {
      const registration = await navigator.serviceWorker.getRegistration()
      if (registration) {
        registration.update()
      }

      await Timeout.set(15 * 60 * 1000)
    }
  }
}
