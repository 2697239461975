import cuid from 'cuid'
import Libhoney from 'libhoney'

const base = {
  // NOTE(ibash) these come from the next.js build, _not_ from electron.
  build_at: process.env.buildAt,
  build_id: process.env.buildId,
  service_name: 'app',
  session_id: cuid()
}

const user = {}

const honey = new Libhoney({
  dataset: process.env.NEXT_PUBLIC_HONEYCOMB_DATASET,
  writeKey: process.env.NEXT_PUBLIC_HONEYCOMB_API_KEY
})

// TODO(ibash) at some point we should settle on naming conventions for metrics
honey.track = (name, data) => {
  const event = honey.newEvent()
  event.add(base)
  event.addField('client_timestamp', new Date().getTime())
  event.addField('name', name)
  event.add(data)

  if (user.id) {
    event.addField('user.id', user.id)
  }

  if (user.name) {
    event.addField('user.name', user.name)
  }

  event.send()
}

honey.setUser = (id, name) => {
  user.id = id
  user.name = name
}

export default honey
