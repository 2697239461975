import Bugsnag from '@bugsnag/js'
import autoBind from 'auto-bind'
import { store } from '@risingstack/react-easy-state'

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL
const keytarService = process.env.NEXT_PUBLIC_KEYTAR_SERVICE

let crypto, execa, ipcRenderer, keytar, promisify, randomBytes, shell

if (process.browser) {
  const electron = window.require('electron')
  crypto = window.require('crypto')
  execa = window.require('execa')
  keytar = window.require('keytar')
  promisify = window.require('util').promisify

  ipcRenderer = electron.ipcRenderer
  randomBytes = promisify(crypto.randomBytes)
  shell = electron.shell
}

export default class Auth {
  constructor() {
    autoBind(this)
    if (process.browser) {
      window.auth = this
    }
    this._refresh = Promise.debounce(this._refresh)
    this.store = store({
      notification: null
    })
    this.reloadData = () => {}
  }

  start() {
    ipcRenderer.on('open-url', this._onOpenUrl)
  }

  stop() {
    ipcRenderer.removeListener('open-url', this._onOpenUrl)
  }

  login(provider) {
    shell.openExternal(`${baseUrl}/auth/${provider}`)
  }

  async logout({ reset } = {}) {
    if (reset) {
      await keytar.deletePassword(keytarService, 'installToken')
      await keytar.deletePassword(keytarService, 'accessToken')
    }

    if (reset) {
      window.location.reload(true)
    }
  }

  async withAuth(callback) {
    // TODO(ibash) pass the actual request into here
    if (this._willAuthError()) {
      await this._refresh()
    }

    let error
    try {
      const result = await callback(this._state)
      return result
    } catch (_error) {
      error = _error
    }

    if (this.isAuthError(error)) {
      // try again after refreshing
      await this._refresh()
      return await callback(this._state)
    } else {
      // TODO(ibash) test this case
      throw error
    }
  }

  // TODO(ibash) if it's a potentially transient error (like a network error),
  // then retry
  // TODO(ibash) need to be able to actually refresh tokens...
  async _refresh() {
    const accessToken = await this._loadAccessToken()
    if (accessToken) {
      this._state = { accessToken }
    } else {
      this._state = null
    }
  }

  isAuthError(error) {
    // TODO(ibash) also check network errors...?
    const graphQLErrors = error.graphQLErrors || []
    return graphQLErrors.some(
      e => e.extensions && e.extensions.code === 'UNAUTHENTICATED'
    )
  }

  _willAuthError() {
    // TODO(ibash) check jwt expiration
    if (!this._state || !this._state.accessToken) {
      return true
    }
    return false
  }

  async _loadAccessToken() {
    const accessToken = await keytar.getPassword(keytarService, 'accessToken')
    if (accessToken) {
      return accessToken
    }

    return this._loginWithInstallToken()
  }

  // The install token is a randomly generated token that's stored on the
  // desktop. It's essentially used as a password so that users don't need to
  // register or login in order to use the app.
  //
  // This is only done when there isn't an existing access token, and when the
  // install hasn't been done before.
  async _loginWithInstallToken() {
    let installToken = await keytar.getPassword(keytarService, 'installToken')

    if (!installToken) {
      // generate a new one
      installToken = (await randomBytes(48)).toString('hex')
    }

    let name = ''
    try {
      const { stdout } = await execa('id', ['-F'])
      name = stdout
    } catch (error) {
      Bugsnag.notify(error)
    }

    // two cases:
    // 1. installToken was not stored in keytar (that is, we just generated a new one)
    // 2. installToken was stored in keytar, which means it was used before
    //
    // In the first case the app/install call should succeed (create a new
    // install) and return an access token.
    //
    // In the second case the app/install call may or may not return an access
    // token. If there are other accounts (e.g. slack) the user can use to
    // login, then the backend _won't_ provide an access token, since we expect
    // the user to use that instead.
    const response = await fetch(`${baseUrl}/auth/app/install`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Summon-Build-Id': process.env.buildId
      },
      body: JSON.stringify({ installToken, name }),
      mode: 'cors'
    })

    const data = await response.json()

    if (data.accessToken) {
      await keytar.setPassword(keytarService, 'installToken', installToken)
      await keytar.setPassword(keytarService, 'accessToken', data.accessToken)
      return data.accessToken
    } else {
      Bugsnag.notify(
        new Error(`unhandled install response: ${JSON.stringify(data)}`)
      )
      return null
    }
  }

  async _onOpenUrl(event, url) {
    const parsed = new URL(url)

    if (!parsed.pathname.endsWith('/auth')) {
      return
    }

    const code = parsed.searchParams.get('code')

    // supplying the accessToken lets us merge accounts
    const previousAccessToken = await keytar.getPassword(
      keytarService,
      'accessToken'
    )
    const headers = {
      'X-Summon-Build-Id': process.env.buildId
    }
    if (previousAccessToken) {
      headers.Authorization = `Bearer ${previousAccessToken}`
    }

    const response = await fetch(`${baseUrl}/auth/exchange?code=${code}`, {
      headers
    })

    const { accessToken } = await response.json()
    await keytar.setPassword(keytarService, 'accessToken', accessToken)
    this._pendingAccessToken = accessToken

    if (window.desktop && window.desktop.showMenubar) {
      window.desktop.showMenubar()
    }

    // TODO(ibash) if they added slack, tell them slack was added, etc
    this.store.notification = {
      at: new Date().getTime(),
      message: 'Account connected!'
    }

    this.reloadData()
  }
}
