import Auth from 'services/data/auth'
import autoBind from 'auto-bind'
import { GraphQLClient } from 'graphql-request'
import { QueryClient, focusManager, hashQueryKey } from 'react-query'
import {
  Client as SubscriptionClient,
  createClient as createSubscriptionClient
} from 'graphql-ws'

//import { ReactQueryDevtools } from "react-query/devtools";

class Client {
  auth: Auth
  graphqlClient: GraphQLClient
  queryClient: QueryClient
  subscriptionClient: SubscriptionClient

  private restartSubscription = () => {
    this.restartRequestedBeforeConnected = true
  }

  private restartRequestedBeforeConnected = false

  constructor() {
    autoBind(this)
    this._init()
  }

  _init() {
    this.auth = new Auth()
    this.graphqlClient = new GraphQLClient(process.env.NEXT_PUBLIC_API_URL, {
      headers: {
        'X-Summon-Build-Id': process.env.buildId
      }
    })
    if (process.browser) {
      this.subscriptionClient = createSubscriptionClient({
        url: process.env.NEXT_PUBLIC_WS_URL,
        connectionParams: () => {
          if (!this.auth._state) {
            return {}
          }

          return this.auth._state
        },
        on: {
          connected: (socket: any) => {
            this.restartSubscription = () => {
              if (socket.readyState === WebSocket.OPEN) {
                socket.close(4205, 'Client Restart')
              }
            }

            // just in case you were eager to restart
            if (this.restartRequestedBeforeConnected) {
              this.restartRequestedBeforeConnected = false
              this.restartSubscription()
            }
          }
        }
      })
    }
    this.queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          queryKeyHashFn: this.queryKeyHashFn,
          refetchInterval: 10 * 60 * 1000,
          refetchIntervalInBackground: true,
          refetchOnReconnect: 'always',
          refetchOnWindowFocus: 'always'
        }
      }
    })

    this.auth.reloadData = () => {
      this.queryClient.invalidateQueries()
      this.restartSubscription()
    }

    // force the focus manager to always be focused, otherwise queries aren't
    // retried in the background
    focusManager.setFocused(true)

    if (process.browser) {
      this.auth.start()
    }
  }

  // assumes that the first item in the queryKey is a graphql query, and that
  // each document name is unique
  queryKeyHashFn(queryKey) {
    const [gql, ...rest] = queryKey
    const name = gql.definitions[0].name.value
    const hashed = hashQueryKey([name, ...rest])
    return hashed
  }

  async queryFn({ queryKey }) {
    const [query, variables] = queryKey
    const data = await this.auth.withAuth(async ({ accessToken }) => {
      const result = await this.graphqlClient.request(query, variables, {
        Authorization: `Bearer ${accessToken}`
      })

      return result
    })
    return data
  }

  async mutationFn(query, variables) {
    const data = await this.auth.withAuth(async ({ accessToken }) => {
      return this.graphqlClient.request(query, variables, {
        Authorization: `Bearer ${accessToken}`
      })
    })
    return data
  }
}

const client = new Client()
export default client
